import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				ТехноСервіс Плюс
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:title"} content={"ТехноСервіс Плюс"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Contact>
		<Components.Nav />
		<Section padding="180px 0 180px 0" md-padding="100px 0 100px 0" quarkly-title="Content-12" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.65) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12:20:25.523Z) 50% 50% /cover repeat scroll padding-box">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white">
					Контактна інформація
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Ми працюємо з понеділка по п'ятницю з 9:00 до 18:00 та в суботу з 10:00 до 14:00. У неділю ми не працюємо. Ви можете записатися на ремонт або консультацію заздалегідь, щоб уникнути черг та отримати послуги у зручний для вас час.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="--headline2"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Приєднуйтесь до нас сьогодні
			</Text>
			<Text
				margin="16px 40% 50px 0px"
				font="--base"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="--dark"
				lg-max-width="720px"
			>
				Щоб замовити ремонт або отримати консультацію, зв'яжіться з нами за телефоном. Ми завжди раді допомогти вам та відповісти на всі ваші питання. Наш сервісний центр знаходиться за зручною адресою, що дозволяє легко дістатися до нас з будь-якої частини міста. Чекаємо на ваші дзвінки та заявки!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Адреса
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Bunina St, д. 37, Odesa, Odesa Oblast, 65045
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Номер телефону
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						0678183132
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact>
			<Override slot="link1">
				0678183132
			</Override>
			<Override slot="link">
				Bunina St, д. 37, Odesa, Odesa Oblast, 65045
			</Override>
		</Components.Contact>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});